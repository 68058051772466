import React from 'react';
import * as R from 'ramda';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Form } from 'react-final-form';
import { useModalContext, useNotificationState } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';
import { string } from 'prop-types';

import { UserForm } from './UserForm.js';
import { prepareClientUserFormDataForMutation, validateUser } from './utils.js';
import { clientUserToInitialFormData } from './clientUserToInitialFormData.js';

export const editUserFormId = 'editUserFormId';

const UPDATE_USER_WITH_USER_GROUPS_MUTATION = gql`
  mutation UPDATE_USER_WITH_USER_GROUPS_MUTATION(
    $input: UpdateUserWithUserGroupsInput!
  ) {
    updateUserWithUserGroups(update: $input) {
      _id
    }
  }
`;

const CLIENT_USER_QUERY = gql`
  query CLIENT_USER_QUERY($userId: ID!) {
    user(id: $userId) {
      _id
      firstName
      lastName
      email
      profile {
        cellPhoneNumber
        workPhoneNumber
      }
      status
    }
  }
`;

export function EditUserForm({ userId }) {
  const { data, loading } = useQuery(CLIENT_USER_QUERY, {
    variables: { userId },
    fetchPolicy: 'network-only',
  });

  const [updateUser] = useMutation(UPDATE_USER_WITH_USER_GROUPS_MUTATION);

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();

  const handleSubmit = async (values) => {
    await updateUser({
      variables: {
        update: prepareClientUserFormDataForMutation(values),
      },
    });

    showSuccessNotification('User updated');

    closeModal(editUserFormId);
  };

  const user = R.propOr({}, 'user', data);

  const initialValues = clientUserToInitialFormData(user);

  if (loading) {
    return <Loader />;
  }

  return (
    <Form
      initialValues={initialValues}
      render={UserForm}
      onSubmit={handleSubmit}
      formId={editUserFormId}
      validate={validateUser}
    />
  );
}

EditUserForm.propTypes = {
  userId: string.isRequired,
};
