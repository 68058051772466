import React, { useState } from 'react';
import {
  TableCardWithoutTabs,
  PageHeaderContainer,
  DefaultBodyWrapper,
  PageHeaderLinks,
  Heading,
} from 'poly-book-admin';

import {
  SupplierTaxReportPrintPDFBtn,
  SuppliersTaxReportExportXLSBtn,
} from './components.js';
import { SearchInput } from '../../../containers/SearchInput.js';
import { YearSelect } from '../../../components/selects/YearSelect.js';
import { ToolBarBtnDivider } from '../../../components/lib/toolBar.js';
import { START_YEAR_PERIOD } from '../../../utils/constants/general.js';
import { SupplierTaxReportTable } from './SupplierTaxReportTable/SupplierTaxReportTable.js';

export function SupplierTaxReportPage() {
  const [year, setYear] = useState(START_YEAR_PERIOD);

  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">Supplier Tax Report</Heading>
        <PageHeaderLinks
          topLinks={
            <>
              <SupplierTaxReportPrintPDFBtn {...{ year }} />
              <ToolBarBtnDivider />
              <SuppliersTaxReportExportXLSBtn {...{ year }} />
            </>
          }
          bottomLinks={
            <>
              <YearSelect value={year} onChange={setYear} required />
              <SearchInput />
            </>
          }
        />
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <TableCardWithoutTabs>
          <SupplierTaxReportTable {...{ year }} />
        </TableCardWithoutTabs>
      </DefaultBodyWrapper>
    </>
  );
}

SupplierTaxReportPage.displayName = 'SupplierTaxReportPage';
