import styled from 'styled-components';
import React from 'react';
import {
  Heading,
  WhiteCard,
  DefaultBodyWrapper,
  PageHeaderContainer,
} from 'poly-book-admin';
import { ApprovalTable } from './ApprovalTable/ApprovalTable.js';
import { CreateBatchInvoicesFooter } from './CreateBatchInvoicesFooter.js';

const WhiteCardS = styled(WhiteCard)`
  height: calc(100% - 90px);
`;

export function ApprovalPage() {
  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">Invoice Approval</Heading>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <WhiteCardS>
          <ApprovalTable />
        </WhiteCardS>
        <CreateBatchInvoicesFooter />
      </DefaultBodyWrapper>
    </>
  );
}
