import * as R from 'ramda';
import { useQuery, gql } from '@apollo/client';
import React from 'react';
import { string, shape } from 'prop-types';
import styled from 'styled-components';
import {
  Heading,
  DefaultBodyWrapper,
  PageHeaderContainer,
  Table,
  TextButton,
  SectionsWrapper,
  Icon,
  getThemeColor,
  useTableSortable,
  Loader,
} from 'poly-book-admin';
import {
  useOpenModal,
  SearchInputComp,
  useMapConfigToTableProps,
} from 'poly-admin-ui';
import { SupplierLink, PropertyLink } from '../../containers/links/Links.js';
import { ModalNames } from '../../utils/constants/modals.js';
import {
  useSearchTextState,
  filterBySearchTextWithHighlight,
  getHighlightedSearchItemValue,
} from './helpers.js';
import { HolderS } from './styles.js';

const CopyIcon = styled(Icon).attrs((props) => ({
  color: getThemeColor(['newPrimary'])(props),
  name: 'clipboard',
  size: 13,
}))`
  cursor: pointer;
`;

const createInvoiceModalPayload = (payload = {}) => ({
  name: ModalNames.CREATE_EDIT_RECURRING_INVOICE_MODAL,
  payload,
});

function AddRecurringInvoiceButton() {
  const openModalWindow = useOpenModal();
  const onAddNewClick = () => {
    openModalWindow(createInvoiceModalPayload());
  };
  return <TextButton onClick={onAddNewClick}>Add New</TextButton>;
}

function EditRecurringInvoiceButton({ invoice }) {
  const openModalWindow = useOpenModal();
  const onEditClick = () => {
    openModalWindow(createInvoiceModalPayload({ invoice }));
  };
  return <TextButton onClick={onEditClick}>Edit</TextButton>;
}

EditRecurringInvoiceButton.propTypes = {
  invoice: shape({ _id: string.isRequired }).isRequired,
};

function CopyRecurringInvoiceButton({ invoice }) {
  const openModalWindow = useOpenModal();
  const onCopyInvoice = () => {
    const initialInvoiceData = R.omit(
      ['supplierOuterContractId', '_id'],
      invoice,
    );
    openModalWindow(createInvoiceModalPayload({ invoice: initialInvoiceData }));
  };
  return <CopyIcon onClick={onCopyInvoice} />;
}

CopyRecurringInvoiceButton.propTypes = {
  invoice: shape({ _id: string.isRequired }).isRequired,
};

const recurringOCRInvoicesQuery = gql`
  query RECURRING_OCR_INVOICES_QUERY {
    recurringOCRInvoices {
      _id
      referenceId
      receiptMethod
      supplier {
        _id
        company {
          name
        }
      }
      property {
        _id
        name
      }
      supplierOuterContractId
      endDayOfTheMonth
    }
  }
`;

// getSupplierNameByInvoice :: RecurringOCRInvoice -> String
const getSupplierNameByInvoice = R.path(['supplier', 'company', 'name']);

// getPropertyNameByInvoice :: RecurringOCRInvoice -> String
const getPropertyNameByInvoice = R.path(['property', 'name']);

const recurringOCRInvoicesTableConfig = [
  [
    'RINV #',
    getHighlightedSearchItemValue(R.prop('referenceId')),
    R.prop('referenceId'),
  ],
  [
    'Supplier',
    (invoice) => (
      <SupplierLink
        name={getHighlightedSearchItemValue(getSupplierNameByInvoice)(invoice)}
        _id={R.path(['supplier', '_id'], invoice)}
      />
    ),
    getSupplierNameByInvoice,
  ],
  [
    'Property',
    (invoice) => (
      <PropertyLink
        name={getHighlightedSearchItemValue(getPropertyNameByInvoice)(invoice)}
        _id={R.path(['property', '_id'], invoice)}
      />
    ),
    getPropertyNameByInvoice,
  ],
  [
    'Account Number',
    getHighlightedSearchItemValue(R.prop('supplierOuterContractId')),
    R.prop('supplierOuterContractId'),
  ],
  [
    'Expected Day of Month',
    getHighlightedSearchItemValue(R.prop('endDayOfTheMonth')),
    R.prop('endDayOfTheMonth'),
  ],
  ['', (invoice) => <EditRecurringInvoiceButton invoice={invoice} />],
  ['', (invoice) => <CopyRecurringInvoiceButton invoice={invoice} />],
];

const TableS = styled(Table)`
  th:nth-child(1),
  td:nth-child(1) {
    width: 150px;
  }
  th:nth-child(5),
  td:nth-child(5) {
    width: 100px;
  }
  th:nth-child(6),
  td:nth-child(6) {
    width: 60px;
  }
  th:nth-child(7),
  td:nth-child(7) {
    width: 40px;
  }
`;

const recurringInvoicesSearchPaths = [
  ['referenceId'],
  ['supplier', 'company', 'name'],
  ['property', 'name'],
  ['receiptMethod'],
  ['supplierOuterContractId'],
  ['endDayOfTheMonth'],
];

// propsToFilteredRecurringOCRInvoices :: TableProps -> [RecurringOCRInvoice]
//   TableProps = {searchText: String, recurringOCRInvoices: [RecurringOCRInvoice]}
const propsToFilteredRecurringOCRInvoices = (props) =>
  R.compose(
    filterBySearchTextWithHighlight(
      recurringInvoicesSearchPaths,
      props.searchText,
    ),
    R.propOr([], 'recurringOCRInvoices'),
  )(props);

function RecurringInvoicesTable(props) {
  const tableConfigProps = useMapConfigToTableProps(
    propsToFilteredRecurringOCRInvoices,
    recurringOCRInvoicesTableConfig,
    props,
  );
  const tableSortProps = useTableSortable(tableConfigProps);
  return <TableS {...props} {...tableConfigProps} {...tableSortProps} />;
}

function SearchRecurringInvoicesInput(props) {
  return (
    <SearchInputComp
      {...props}
      name="search-recurring-invoices"
      placeholder="Search"
    />
  );
}

const HeaderControlsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 350px;
`;

export function RecurringInvoicesPage() {
  const [searchText, onSearchTextChange] = useSearchTextState();

  const { data, loading } = useQuery(recurringOCRInvoicesQuery, {
    fetchPolicy: 'network-only',
  });

  return (
    <>
      <PageHeaderContainer>
        <Heading size="h2">Recurring Invoices</Heading>
        <HeaderControlsWrapper>
          <AddRecurringInvoiceButton />
          <SearchRecurringInvoicesInput
            value={searchText}
            onChange={onSearchTextChange}
          />
        </HeaderControlsWrapper>
      </PageHeaderContainer>
      <DefaultBodyWrapper>
        <HolderS>
          <SectionsWrapper>
            {loading ? (
              <Loader />
            ) : (
              <RecurringInvoicesTable
                searchText={searchText}
                recurringOCRInvoices={data?.recurringOCRInvoices}
              />
            )}
          </SectionsWrapper>
        </HolderS>
      </DefaultBodyWrapper>
    </>
  );
}
