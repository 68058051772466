import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { func, string, number, bool } from 'prop-types';
import { useRouterParams } from 'poly-client-routing';
import {
  useOnSubmitSetStopSubmitting,
  closeCurrentModal,
  useProcessState,
} from 'poly-admin-ui';
import {
  useNotificationContext,
  ModalActions,
  getThemeFont,
  Heading,
  Button,
} from 'poly-book-admin';

import { approveInvoicesFormId } from './BatchPage/common/constants.js';
import { setSelectedRows } from '../../state/modules/selected-rows.js';
import { getFormattedInvoicesTotal } from '../../utils/invoices.js';
import { useApproveInvoices } from './apollo/useApproveInvoices.js';
import { useSelectedInvoices } from '../../utils/hooks/apollo.js';

const ModalContentS = styled.div`
  width: 490px;
`;

const ModalText = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  font-size: 14px;
  line-height: 1.5;
  margin: 30px 0;
`;

const CancelButton = styled(Button).attrs(() => ({
  size: 'small',
  styleType: 'basicSecondary',
  type: 'button',
}))`
  margin-right: 20px;
`;

function ApproveInvoicesModalC({
  onCancel,
  onSubmit,
  invoicesCount,
  total,
  loading,
}) {
  return (
    <ModalContentS>
      <Heading size="h3">Approve for Payment</Heading>
      <ModalText>
        {`You are about to approve ${invoicesCount} invoice(s) totaling ${total}`}
        <p>Is it ok to proceed?</p>
      </ModalText>
      <ModalActions>
        <CancelButton onClick={onCancel} disabled={loading}>
          Cancel
        </CancelButton>
        <Button
          onClick={onSubmit}
          size="small"
          loader={loading}
          disabled={loading}
        >
          Approve
        </Button>
      </ModalActions>
    </ModalContentS>
  );
}

ApproveInvoicesModalC.propTypes = {
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  invoicesCount: number.isRequired,
  total: string.isRequired,
  loading: bool,
};

// getInvoicesTotal :: SearchInvoicesResult -> String
const getInvoicesTotal = R.compose(
  getFormattedInvoicesTotal,
  R.defaultTo([]),
  R.prop('hits'),
);

// getInvoicesCount :: SearchInvoicesQueryResult -> Number
const getInvoicesCount = R.pathOr(0, ['searchOCRInvoices', 'hits', 'length']);

export function ApproveInvoicesModal() {
  const dispatch = useDispatch();
  const { process } = useProcessState(approveInvoicesFormId);

  const { showSuccessNotification } = useNotificationContext();

  const { batchId } = useRouterParams(['batchId']);

  const { data, loading, selectedRows } = useSelectedInvoices();

  const { approveBatch } = useApproveInvoices();

  const onSubmitHandler = async () => {
    await approveBatch({
      batchId,
      invoiceIds: selectedRows,
    });

    dispatch(closeCurrentModal());
    dispatch(setSelectedRows([]));
    showSuccessNotification('The Batch Has Been Approved');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    approveInvoicesFormId,
    onSubmitHandler,
  );

  return (
    <ApproveInvoicesModalC
      onSubmit={onSubmit}
      loading={loading || process}
      invoicesCount={getInvoicesCount(data)}
      onCancel={() => dispatch(closeCurrentModal())}
      total={getInvoicesTotal(data?.searchOCRInvoices)}
    />
  );
}
